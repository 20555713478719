function addPatchesToComponentForPrismUIIssue893(
  component: HTMLPrismInputOtpElement
) {
  addValidationErrorMessagePatch(component);
  addDelegateInputPatch(component);
}

function addValidationErrorMessagePatch(component: HTMLPrismInputOtpElement) {
  component.addEventListener("prismInvalid", () => {
    component.setAttribute("invalid", "true");
    if (component.customValidity?.allRequired) {
      component.setAttribute(
        "invalid-message",
        component.customValidity.allRequired
      );
    }
  });
}

function addDelegateInputPatch(component: HTMLPrismInputOtpElement) {
  component.addEventListener("prismChange", () => {
    const value = Array.from(
      component.querySelectorAll<HTMLInputElement>("input:not([type=hidden])")
    )
      .map((field) => field.value)
      .join("");
    const input = component.querySelector(
      `input[name="${component.getAttribute("name")}"]`
    ) as HTMLInputElement;
    input.setAttribute("value", value);
  });
}

export default () => {
  const inputOtpList =
    document.querySelectorAll<HTMLPrismInputOtpElement>("prism-input-otp");
  inputOtpList.forEach(addPatchesToComponentForPrismUIIssue893);
};
